import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'

export default {
  install: (app) => {
    app.use(ElementPlus, {
      locale: zhCn,
      size: 'default',
      experimentalFeatures: {
        // 禁用实验性功能
        vxeTable: false,
        // 设置更保守的 resize 策略
        resizeObserver: false
      }
    })
  }
} 