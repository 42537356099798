import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlugin from './plugins/element'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)

// 注册所有图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 使用 Element Plus
app.use(ElementPlugin)
app.use(router)
app.use(store)

// 全局错误处理
const ignoreErrors = [
  'ResizeObserver loop completed with undelivered notifications.',
  'ResizeObserver loop limit exceeded'
]

app.config.errorHandler = (err) => {
  if (err.message && ignoreErrors.some(msg => err.message.includes(msg))) {
    return
  }
  console.error(err)
}

// 重写 console.error
const originalError = console.error
console.error = (...args) => {
  if (args[0] && typeof args[0] === 'string' && 
      ignoreErrors.some(msg => args[0].includes(msg))) {
    return
  }
  originalError.apply(console, args)
}

// 全局事件处理
window.addEventListener('error', (event) => {
  if (event.message && ignoreErrors.some(msg => event.message.includes(msg))) {
    event.stopPropagation()
    event.preventDefault()
  }
}, true)

// 禁用特定 MutationObserver 警告
const mutationObserver = window.MutationObserver
window.MutationObserver = class extends mutationObserver {
  constructor(callback) {
    super((mutations, observer) => {
      try {
        callback(mutations, observer)
      } catch (e) {
        if (!ignoreErrors.some(msg => e.message.includes(msg))) {
          throw e
        }
      }
    })
  }
}

app.mount('#app')

const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}