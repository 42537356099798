import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/',
    redirect: '/dashboard',
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/agencies',
    name: 'Agencies',
    component: () => import('../views/Agencies.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/selection',
    name: 'Selection',
    component: () => import('../views/Selection.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('../views/Reports.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('../views/Projects.vue'),
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  const user = localStorage.getItem('user')
  
  // 如果要访问的页面需要认证
  if (to.meta.requiresAuth) {
    // 如果没有token或user信息，重定向到登录页
    if (!token || !user) {
      next({ path: '/login', query: { redirect: to.fullPath } })
    } else {
      next()
    }
  } else {
    // 如果已经登录且要访问登录页，重定向到首页
    if (token && user && to.path === '/login') {
      next({ path: '/' })
    } else {
      next()
    }
  }
})

export default router 