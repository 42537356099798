<template>
  <div class="app-container" v-if="$route.meta.requiresAuth">
    <div class="sidebar">
      <div class="app-title">招标代理机构随机抽取</div>
      <el-menu
        :default-active="activeIndex"
        class="sidebar-menu"
        background-color="#304156"
        text-color="#fff"
        active-text-color="#409EFF"
        router>
        <el-menu-item index="/dashboard">
          <el-icon><DataLine /></el-icon>
          <span>首页</span>
        </el-menu-item>
        <el-menu-item index="/agencies">
          <el-icon><List /></el-icon>
          <span>代理机构管理</span>
        </el-menu-item>
        <el-menu-item index="/projects">
          <el-icon><Folder /></el-icon>
          <span>项目管理</span>
        </el-menu-item>
        <el-menu-item index="/selection">
          <el-icon><Select /></el-icon>
          <span>项目抽取</span>
        </el-menu-item>
        <el-menu-item index="/reports">
          <el-icon><Document /></el-icon>
          <span>报表管理</span>
        </el-menu-item>
      </el-menu>
      <div class="user-info">
        <span>{{ user?.realName || user?.username }}</span>
        <el-button type="text" @click="handleLogout">退出</el-button>
      </div>
    </div>
    <div class="main-content">
      <router-view></router-view>
    </div>
  </div>
  <router-view v-else></router-view>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { DataLine, List, Select, Document, Folder } from '@element-plus/icons-vue'
import { ElMessageBox } from 'element-plus'

const store = useStore()
const router = useRouter()
const activeIndex = ref('/dashboard')

const user = computed(() => store.state.user)

const handleLogout = async () => {
  try {
    await ElMessageBox.confirm('确定要退出登录吗？', '提示', {
      type: 'warning'
    })
    store.dispatch('logout')
    router.push('/login')
  } catch (error) {
    // 用户取消退出
  }
}
</script>

<style>
/* 全局样式 */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

/* 隐藏滚动条但保持滚动功能 */
* {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}
</style>

<style scoped>
.app-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.sidebar {
  width: 240px;
  background-color: #304156;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.app-title {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #1f2d3d;
}

.sidebar-menu {
  border-right: none;
  flex: 1;
  overflow-y: auto;
}

.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

.user-info {
  padding: 10px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #1f2d3d;
}

.user-info :deep(.el-button) {
  color: #fff;
}
</style> 